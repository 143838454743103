import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Link from "../components/Link"
import Img from "gatsby-image"

import { extractBlogPosts, getSortedGroups, groupPostsByYear } from "../utils"

import externalLinks from "../content/articles/externalLinks"
import SayHi from "../components/SayHi"
import { Box } from "../components/Box"
import { Heading1,  Heading2, Paragraph, TextEm ,TextSmall} from "../components/Typography"
import List from "../components/List/List"

const Writings = ({ data }) => {
  const blogPosts = getSortedGroups(
    groupPostsByYear(extractBlogPosts(data).concat(externalLinks))
  )

const ListHeading2 = styled(Heading2)`
  margin:0;
`

const PinBanner = styled(Box)`
  display: flex;
  flex-direction: row;
  @media (max-width: 414px) {
    flex-direction: column;
  
  }
`
const PinPic = styled.div`
  text-align:center;
    width:200px;
  @media (max-width: 414px) {
      margin:0px auto;
      width:100%;
  }
`


  return (
    <Layout>
      <Box maxWidth="640px" marginTop={20} element="section">
        <SEO title="写作" />
        <Heading1> <span role="img">✍️</span> 我的博客</Heading1>
        <PinBanner element="section"  >
       
        <Paragraph>
          我会不定期更新，关于技术、管理、生活等方面的思考。
        </Paragraph>
        <PinPic><Img fluid={data.file.childImageSharp.fluid} alt="avenirzheng"/></PinPic>
        </PinBanner>
        

        <Box marginTop={10} element="section">
        {blogPosts.map(([key, posts]) => {
            return (
              <Box>
                <List title={key} key={key}>
                  {posts.map((post, index) => (
                    <List.Item link={post.link} key={index} subtitle={post.date}>
                      <ListHeading2 marginTop={0} >{post.title}</ListHeading2>
                    </List.Item>
                  ))}
                </List>
              </Box>
            )
          })}
         
       
        <Box marginTop={12}>
        <Paragraph>
        <Link href="/archive"><TextSmall>存档内容 2007年 - 2013年</TextSmall></Link>
          
        </Paragraph>
        
          </Box>
        </Box>
        <Box marginTop={20} marginBottom={32} element="section">
          <SayHi />
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {date: {gte: "2014-01-02"}}}) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
          }
          fields {
            slug
          }
        }
      }
    },
    file(relativePath: { eq: "images/writings/reading.png" }) {
      childImageSharp {
        fluid(maxWidth: 300,quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default Writings
